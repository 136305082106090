@media (min-width: 1000px) {

    html, body, .wrapper, main, main > div {
        height: auto;
    }

    button, label.button {
        cursor: pointer;
    }

    header, main, footer {
        max-width: 1024px;
        margin: auto;
        position: relative;
    }

    header button.refresh,
    header a.account {
        float: left;
        position: relative;
        margin-top: -80px;
        margin-left: 985px;
    }

    main .standard {
        height: auto;
    }

    main .contentWrapper {
        margin: 0 10px;
    }

    .LoadingDiv {
        padding-top: 10%;
    }

    .LoadingDiv img {
        width: 10%;
    }

    footer {
        margin-top: 25px;
        margin-bottom: 10px;
    }

    footer nav {
        width: auto;
    }

    footer ul, footer ul li {
        float: none;
    }

    footer ul {
        margin: auto;
    }

    .summary ul label {
        width: 200px;
    }


}
